import React from "react";
import "../styles/Admin.css";

// Import other components

function Admin() {
  return (
      <div className="adminRightSide">
        <div>
          <h1>Admin Page</h1>
        </div>
      </div>
  );
}

export default Admin;