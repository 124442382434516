import React from "react";

import "../styles/Locations.css";

function Locations(){
    return (
<div>
    <p>
        location/hours/events of each location using google api
    place google calender here
    tournaments chart
    </p>

</div>
);
}

export default Locations;